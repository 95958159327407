import { addPaginationLinks, getCursorLinks } from './cursor.js'
import { fetchBlog, fetchBlogs, fetchComments } from './fetch.js'

async function main () {
    const currentLocation = new URL(window.location.href)

    if (currentLocation.pathname === '/' || currentLocation.pathname === '/index.html') {
        await listBlogs(currentLocation)
    }

    if (currentLocation.pathname === '/blog' || currentLocation.pathname === '/blog.html') {
        await displayBlog(currentLocation)
    }    
}


/**
 * 
 * @param {URL} parsedURL 
 */
async function listBlogs (parsedURL) {    
    const container = document.querySelector('.container')
    const pageToken = parsedURL.searchParams.get('pageToken')

    const options = {}

    if (pageToken) {
        options.pageToken = pageToken
    }

    const blogsResponse = await fetchBlogs(options)
    const blogs = blogsResponse.result.documents

    const blogsArray = blogs.map(blog => `
        <div class="row">
                <div class="col">
                    <div class="card" style="padding: 5px;">
                        <div class="card-body>
                            <h1 class="card-title"><b>${blog.name}</b></h1>
                            <p class="card-text">${blog.fields.blogcontent.stringValue.slice(0, 100) + '...'}</p>
                            <a href="/blog?name=${blog.name}">Go to blog</a>
                        </div>
                    </div>
                </div>
        </div>
        <br/>
    `).join('')

    container.innerHTML = blogsArray
    container.appendChild(addPaginationLinks(blogsResponse))
}

async function displayBlog (parsedURL) {
    const blogName = parsedURL.searchParams.get('name')
    const id = blogName.split('/').pop()
    const container = document.querySelector('.container')
    const options = {}

    if (parsedURL.searchParams.get('pageToken')) {
        options.pageToken = parsedURL.searchParams.get('pageToken')
    }

    const [
        blogResult,
        commentsResult
    ] = await Promise.all([
        fetchBlog(id),
        fetchComments(id, options)
    ])

    const blog = blogResult.result
    const comments = commentsResult.result.documents


    function displayBlog () {
        const blogRow = document.createElement('div')
        blogRow.className = 'blog-row row'

        blogRow.innerHTML = `
            <div className="col">
                <h1>Blog Post</h1>
                <h2>${id} by ${blog.fields.user.stringValue}</h2>
                <h3>${blog.fields.blogcontent.stringValue}</h3>
            </div>
        `

        container.appendChild(blogRow)
    }

    function displayComments () {
        const commentsRow = document.createElement('div')
        commentsRow.className = 'comments-row row'

        const commentCards = comments.filter(comment => !!comment.fields).map(comment => `
            <div className="row">
                <div className="col">
                    <hr />
                    <div className="card">
                        <div className="card-body">
                            <h3 className="card-title">${comment.name.split('/').pop()} by ${comment.fields.user.stringValue}</h3>
                        </div>
                    </div>
                    <hr />
                </div>
            </div>
        `)

        commentsRow.innerHTML = `
            <div className="col">
                <h3>Comments Section</h3>
                ${commentCards.join('\n')}
            </div>
        `

        container.appendChild(commentsRow)
    }
    
    
    displayBlog()
    displayComments()
    container.appendChild(addPaginationLinks(commentsResult))
}


main()

